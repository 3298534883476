@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montagu+Slab:opsz,wght@16..144,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
}

.main_container {
  display: grid;
  grid-template-columns: 260px calc(100% - 260px);
  width: 100%;
  height: calc(100vh - 70px);
}

.main_container .sideBar {
  background-color: #fff;
  box-shadow: 1px 0px 10px #202020;
  overflow-x: scroll;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(90, 86, 86);
  border-radius: 10px;
}

.main_container .Outlet {
  width: 100%;
  height: calc(100vh - 70px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 20px;
}

.main_container .sidebarHidden {
  display: none;
}

.main_container.outletFullWidth {
  grid-template-columns: 0px calc(100% - 0px);
  display: block;
}

.header {
  background-color: #202020;
  width: 100%;
  height: 70px;
}

.header .mylogo {
  width: 150px;
}

.header .mobileMenu {
  display: flex;
  align-items: center;
}

.header .mobileMenu .mobileMenuIcon {
  font-size: 30px;
  color: #fff;
}

.sidebar_content {
  padding-top: 20px;
}

.sidebar_content .navlink {
  text-decoration: none;
}

.sidebar_content ul li {
  display: flex;
  width: 100%;
  line-height: 40px;
  margin-bottom: 2px;
  padding: 0 15px;
}

.sidebar_content ul li.active {
  background-color: #202020;
  border-right: 4px solid #6b6b6b;
}

.sidebar_content ul li:hover {
  background-color: #202020;
}

.sidebar_content ul li.active .dashboard_logo,
.sidebar_content ul li.active a,
.sidebar_content ul li:hover .dashboard_logo,
.sidebar_content ul li:hover a {
  color: #fff;
}

.sidebar_content ul li .dashboard_logo {
  color: #202020;
  display: flex;
  align-items: center;
  font-size: 23px;
}

.sidebar_content ul li a {
  font-size: 16px;
  color: #444444;
  font-weight: 500;
  text-decoration: none;
  padding-left: 10px;
}

.linkLineHeight {
  line-height: 26px;
}

/* login form css start */
.loginParent {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

#main_form {
  width: 80%;
  display: flex;
  justify-content: center;
}

#main_form .login-form {
  max-width: 420px;
  width: 100%;
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 1px 21px #000;
}

#main_form .form-group {
  position: relative;
  margin-bottom: 18px;
}

#main_form .form-group label {
  font-weight: 600;
  margin-bottom: 5px;
}

#main_form .form-group input {
  font-size: 18px;
  border-radius: 8px;
  background-color: aliceblue;
  padding: 8px;
}

/* login form css end */

/* card css start*/
.card {
  box-shadow: 0px 0px 10px 0px #eee;
  border: none !important;
  padding: 1rem;
}

.card .icon {
  padding: 20px;
  color: #272121;
}

.cardOuter {
  justify-content: center;
  padding: 6px;
}

.card .icon .icon_svg {
  font-size: 34px;
}

.card .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card .content h3 {
  font-size: calc(0.7rem + 0.7vw);
}

.card .userData {
  font-weight: 600;
}

.content .title h3 {
  text-align: center;
}

.cardsection .textDecoration {
  text-decoration: none;
}

.content .userData {
  text-align: center;
}

/* card css end*/
.addPrize {
  display: flex;
  justify-content: center;
}

.addPrize .addPrize_folder {
  width: 70%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #000;
  padding: 20px;
}

.addPrize .addPrize_Btn {
  float: inline-end;
}

.datagrid {
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.datagrid h5 {
  padding: 10px 20px 10px 20px;
  background: aliceblue;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 1px 1px #c4c4c4;
}

.datagrid .datagridBox {
  padding: 0px 20px 20px 20px;
}

.addScheme {
  display: flex;
  justify-content: center;
}

.addScheme .addScheme_folder {
  width: 70%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #000;
  padding: 20px;
}

.addScheme .add_icon {
  margin-top: -6px;
}

.addScheme .delete_btn {
  font-weight: 700;
}

/* .myContainer {
  box-shadow: 0px 0px 5px #000;
} */
.myContainer .containerCard {
  display: flex;
  justify-content: center;
}

.myContainer .innerContainer .indiviusalCard {
  background-color: #447074;
  color: #fff;
}

.filterByDate .title {
  display: flex;
  align-items: flex-end;
}

.date_number {
  justify-content: space-between;
}

.filterByDate,
.filterByNetwork {
  display: flex;
  gap: 20px;
  width: 80%;
  margin: auto;
}

.datePicker,
.networkPicker {
  display: flex;
  gap: 20px;
}

.containerDataGrid {
  width: 85%;
  margin: auto;
}

.vertical-line {
  border-right: 1px solid #e0e0e0;
  /* Add a solid border to the right of each cell */
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  background-color: #3b3535 !important;
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.activePrizeTable {
  display: flex;
  justify-content: center;
}

.addmyScheme_container .addmyScheme {
  width: 70%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #000;
  padding: 10px;
}
.addmyScheme_container {
  display: grid;
  place-items: center;
}

.addmyScheme_container .label {
  color: red;
}

.viewCsv {
  width: 70%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #000;
}
.activePrizes {
  width: 70%;
  border-radius: 10px;
  padding: 20px;
}

.activePrizes .prizetable th {
  font-weight: 400 !important;
}

.activePrizes h4 {
  border-bottom: 1px solid grey;
}

.activePrizes table th {
  text-align: center;
}

.filtersbymobile {
  margin-bottom: 10px;
}
.filtersbymobile .mobilePicker {
  width: 200px;
}

.custom-icon {
  font-size: 12px;
  line-height: 1;

  border-radius: 2px;
  background-color: #ddd;
  color: #000;
}

.react-toggle--checked .custom-icon {
  background-color: #4caf50;
  color: #fff;
}

.react-toggle--unchecked .custom-icon {
  background-color: #f44336;
  color: #fff;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 10px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: 600 !important;
}

.containerCard {
  gap: 10px;
}
@media screen and (max-width: 992px) and (min-width: 568px) {
  .filterByDate {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 992px) and (min-width: 767px) {
  .cardsection .myCard {
    min-height: 120px;
  }
}

@media screen and (max-width: 767px) {
  .cardsection .myCard {
    min-height: unset;
  }
}

@media screen and (max-width: 567px) {
  .addPrize .addPrize_folder {
    width: 100%;
  }

  .addScheme .addScheme_folder {
    width: 100%;
  }

  .main_container {
    display: block;
    grid-template-columns: 0px calc(100% - 0px);
    width: 100%;
    height: calc(100vh - 70px);
  }

  .main_container .sideBar {
    display: none;
  }

  .main_container .sidebarHidden {
    display: block;
  }

  .main_container.outletFullWidth {
    display: grid;
    grid-template-columns: 260px calc(100% - 260px);
    width: 100%;
  }

  .cardsection .myCard {
    min-height: unset;
  }

  .filterByDate {
    display: flex;
    flex-direction: column;
  }
}

.mc-blacklist-form {
  padding: 1rem;
  width: 85%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 10px #ddd;
  margin: auto;
  margin-bottom: 30px;
}

.mc-blacklist-form h3 {
  text-align: center;
}

.mc-blacklist-form .head {
  border-bottom: 1px solid #ddd;
}

.mc-blacklist-form .form-container {
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.mc-blacklist-form button {
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 8px 2rem;
  font-weight: 500;
}

.ml-auto {
  margin-left: auto;
}

.mc-main-container.send-sms {
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: auto;
  max-width: 500px;
}

.send-sms h3 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 4rem;
}

.send-sms .csv-file-input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.send-sms .csv-file-input-wrapper input {
  width: 50%;
}

.send-sms form button {
  width: 100%;
  padding: 8px 2rem;
  text-align: center;
  background-color: #ddd;
  border-radius: 5px;
  color: black;
  font-weight: 500;
  outline: none;
  border: none;
}

.clear-sms.btn-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto;
}

.clear-sms button {
  border-radius: 5px;
  padding: 8px 1rem;
  outline: none;
  border: none;
  background-color: #447074;
  color: white;
}

.homePage .row .col-lg-3.col-md-4 a .card .cardOuter {
  height: 100%;
  min-height: 100px;
}

.homePage .row .col-lg-3.col-md-4 a .card .cardOuter .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.homePage .row .col-lg-3.col-md-4 a .card .cardOuter .content .title h3 {
  font-size: 1rem;
}

.homePage .row .col-lg-3.col-md-4 a .card .cardOuter .content .userData {
  font-size: 1.75rem;
}

.homePage .row .col-lg-3.col-md-4 a {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.homePage .row .col-lg-3 a .card {
  height: 100%;
  width: 100%;
}

.homePage .row .col-lg-3.col-md-4 {
  display: flex;
  align-items: stretch;
}
